<template>
  <h4 class="text-2xl py-2">+77 <span class="update_date">(2024.04.11)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 기능 문제 발생이 확인 되어 문제를 수정 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+76 <span class="update_date">(2024.04.09)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>RX 스타일러 Steamer 모듈 개선 pgm 1건 추가 되었습니다.</li>
    <li>식기세척기 XO Refresh Best pgm 1건 추가 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+75 <span class="update_date">(2024.03.27)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>모뎀 업데이트 Firmware (총 7종)가 추가 되었습니다.</li>
    <li>SKS36 Wi-Fi SSID 패턴(Signature_Cooktop2)이 추가 되었습니다.</li>
  </ul>
</template>
<script setup>
</script>
