<template>
  <AppLayout v-if="!withOutLayout" :activate="activate">
    <div>
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <nav class="mt-5 space-y-1 px-2">
                    <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                      <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                      {{ item.name }}
                    </a>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
            <div class="w-14 flex-shrink-0">
              <!-- Force sidebar to shrink to fit close icon -->
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <div class="flex flex-1 flex-col">
        <div class="bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button type="button" class="-ml-0.5 -mt-0.5 inline-flex h-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
            <ListBulletIcon class="h-6 w-6 mr-1" aria-hidden="true" />
            <span> Other Versions</span>
          </button>
        </div>
        <main class="flex-1">
          <div class="flex">
            <div class="hidden sticky md:inset-y-0 md:flex md:w-64 md:flex-col">
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                <div class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                  <nav class="flex-1 space-y-1 bg-white px-2">
                    <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                      <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                      {{ item.name }}
                    </a>
                  </nav>
                </div>
              </div>
            </div>

            <div class="py-6 flex-1">
              <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <h1 class="text-2xl font-semibold text-gray-900" v-if="router.currentRoute.value.meta['type'] !== 'install'">
                  {{ router.currentRoute.value.name }}
                </h1>
              </div>
              <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <!-- Replace with your content -->
                <div class="py-4">
                  <router-view />
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </AppLayout>
  <router-view v-else />
</template>
<script setup>
import AppLayout from '@/components/AppLayout'
import { ref, computed } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  DevicePhoneMobileIcon,
  ListBulletIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import {useRouter} from "vue-router/dist/vue-router";
import ReleaseList from "@/release_notes"

const router = useRouter();
const activate = router.currentRoute.value.meta['type'] === "install" ? "install" : "releaseNote"
const withOutLayout = !!router.currentRoute.value.params['withoutLayout'];
console.log('without layout', withOutLayout)

function getTypeIcon(type){
  switch(type){
    case "install" :
      return DevicePhoneMobileIcon;
    default:
      return "V";
  }
}
const navigation = computed(() => {
  let list = [];
  for(let i in ReleaseList){
    list.push({
      name: ReleaseList[i].meta.type === "install" ? "설치하기" : ReleaseList[i].name,
      href: router.resolve({name:ReleaseList[i].name}).href,
      icon: getTypeIcon(ReleaseList[i].meta.type),
      current:
          (router.currentRoute.value.meta['type'] === 'install' && ReleaseList[i].meta.type === "install")
          || router.currentRoute.value.name === ReleaseList[i].name
    });
  }

  return list;
});
console.log(ReleaseList, router)

const sidebarOpen = ref(false)
</script>
