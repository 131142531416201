<template>
  <h4 class="text-2xl py-2">+60 <span class="update_date">(2023.07.12)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 정수기 SAA43162601, SAA43876101 펌웨어 추가 했습니다. </li>
    <li>내장형 FOTA 워시타워 WT27 LCD SAA44256002 모델 추가 했습니다. </li>
    <li>모뎀 업데이트시 제품 FOTA 진행 후 시도하라는 안내 팝업이 추가 되었습니다. </li>
    <li>내장형 FOTA 미지원 모델 대상이 55버전 미만에서 99버전 미만으로 변경되었습니다. </li>
    <li>내장형 FOTA 대상 모델이 130 이상 182 이하 에서 99버전 이상 최신버전 미만으로 변경되었습니다. </li>
    <li>내장형 FOTA 진행시 모뎀 버전이 99버전 미만일 경우 JIG를 사용하도록 안내 문구가 출력됩니다. </li>
    <li>내장형 FOTA 진행시 모뎀 버전이 최신버전이 아닐 경우 모뎀 업데이트를 진행하도록 안내 문구가 출력됩니다. </li>
    <li>SW 다운로드 JIG 리스트 제목이 한눈에 알기 쉽도록 개선되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+59 <span class="update_date">(2023.06.29)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 지원 Modem Version 183이상 Version 부터 지원하도록 상향 조정 했습니다.</li>
    <li>JIG Download 기능 사용시 앱 권한 체크하도록 수정 했습니다. </li>
    <li>세탁기 V+25 H700, V+27 H900 모델을 추가 했습니다.  </li>
  </ul>
  <h4 class="text-2xl py-2">+58 <span class="update_date">(2023.06.16)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>BlackBox => LSS 로 명칭이 변경되었습니다. </li>
    <li>LSS 앱 UI가 개선 되었습니다. </li>
    <li>JIG Download 기능이 추가 되었습니다. </li>
    <li>로그인 시 로딩 아이콘이 표시 되도록 기능 추가했습니다. </li>
  </ul>
</template>
