<template>
  <h4 class="text-2xl py-2">+63 <span class="update_date">(2023.08.03)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>D3 WT FOTA 모델 추가 되었습니다. </li>
    <li>제품 FOTA 모뎀 정보 수신 후 LQC 모드 진입하도록 수정 되었습니다. </li>
    <li>더보기 페이지에서 계정 국가 설정 변경 가능 하도록 기능 추가 되었습니다.</li>
    <li>홈 페이지에서 출력되던 환영 메시지를 출력 하지 않도록 수정 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+62 <span class="update_date">(2023.07.28)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>DeviceType 101(냉장고) 모델의 제품 FOTA 적용 버전을 새로 조정 했습니다. </li>
    <li>360 공기청정기 HIT, 플러스 Wi-Fi 모드 진입 방법 추가 되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+61 <span class="update_date">(2023.07.19)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 인증서를 갱신 했습니다. </li>
    <li>모뎀 업데이트 시 진행률이 표시될 수 있도록 기능이 추가되었습니다. </li>
    <li>제품 FOTA, 모뎀 FOTA 진입 이후 뒤로가기가 불가능해지고 무조건 홈으로 이동하도록 변경되었습니다. </li>
    <li>미지원 모델 안내 시 [modem Version]이 표시되도록 수정되었습니다. </li>
    <li>FOTA 기능 사용 중 로그가 기록되는 시점을 좀 더 보완 했습니다. </li>
  </ul>
</template>
