<template>
  <div class="relative sm:px-6 lg:px-8">
    <h1 class="text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
      <span class="block">LSS</span>
      <span class="block text-blackbox-bg">애플리케이션 설치</span>
    </h1>

    <p class="mx-auto mt-6 max-w-lg text-xl text-blackbox-bg text-opacity-90 sm:max-w-3xl">
      설치를 위해 OS를 선택 하세요.
    </p>

    <div class="mt-12">
      <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
        <div v-for="feature in features" :key="feature.name" class="pt-6">
          <a :href="feature.href">
          <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div class="-mt-6">
              <div>
                  <span class="inline-flex items-center justify-center rounded-md bg-white border-blackbox-bg border p-3 shadow-lg">
                    <component :is="feature.icon" class="h-6 w-6 text-blackbox-bg" aria-hidden="true" />
                  </span>
              </div>
              <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">{{ feature.name }}</h3>
              <p class="mt-5 text-base text-gray-500">{{ feature.description }}</p>
            </div>
          </div>
          </a>
        </div>
      </div>
    </div>

    <hr class="my-12" />
    <h1 class="text-2xl font-semibold text-gray-900">
      최근 릴리즈
    </h1>
    <h2 class="text-gray-700">
      {{ latestVersion.name }}
      <small class="text-gray-400">({{ latestVersion.meta.date }})</small>
    </h2>
    <div class="py-4">
      <component :is="latestVersion.component" />
    </div>
  </div>
</template>

<script setup>
import iosIcon from '@/icons/iphone'
import aosIcon from '@/icons/android'
import {computed} from "vue";
import ReleaseList from "@/release_notes";
import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()


const latestVersion = computed(() => {
  let latest;
  for(let i in ReleaseList){
    if(ReleaseList[i].meta.type === 'install') continue;
    latest = ReleaseList[i];
    break;
  }
  return latest;
});

const features = [
  {
    name: 'Android +13',
    description: '갤럭시 등 안드로이드 사용자를 위한 설치를 안내합니다. 안드로이드 13 (SDK 33)이상만 지원합니다.',
    icon: aosIcon,
    href: router.resolve({ name: 'install_android' }).href
  },
  {
    name: 'iOS +16',
    description: '아이폰 사용자를 위한 설치를 안내합니다. iOS 16버전 이상만 지원합니다.',
    icon: iosIcon,
    href: router.resolve({ name: 'install_ios' }).href
  }
];
</script>
