<template>
  <div class="mt-12">
    <router-link :to="{name:'test-install-home'}" class="flex">
      <ChevronLeftIcon class="w-5" />
      뒤로
    </router-link>
    <div class="grid grid-cols-1">
      <div class="pt-6">
        <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
          <div class="-mt-6">
            <div class="text-center">
              <div>
                <span class="inline-flex items-center justify-center rounded-md bg-white border-blackbox-bg border p-3 shadow-lg">
                  <IosIcon class="h-20 w-20 p-3 text-blackbox-bg" aria-hidden="true" />
                </span>
              </div>
              <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">Fota IOS 테스트파일 다운로드</h3>
              <a :href="iosLink" class="mt-6 inline-flex items-center rounded-md border border-transparent bg-blackbox-bg bg-opacity-90 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blackbox-bg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <ArchiveBoxArrowDownIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                지금 설치하기
              </a>
            </div>

            <hr class="my-5" />

            <h4>설치가 안되나요?</h4>
            <p class="my-5 text-base text-gray-500">OS 최초설치시 기업의 신뢰설정이 필요할 수 있습니다.<br/>
              아래 화면을 참고하여 설정 -> 일반 -> 기기 관리 -> Lg Electronics Inc. -> 신뢰 설정 후 사용 바랍니다.</p>
            <div class="w-full overflow-x-auto touch-pan-x flex">
              <img class="mr-2" width="180" src="@/assets/ios1.png" />
              <img class="mr-2" width="180" src="@/assets/ios2.png" />
              <img class="mr-2" width="180" src="@/assets/ios3.png" />
              <img class="mr-2" width="180" src="@/assets/ios4.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ArchiveBoxArrowDownIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
import IosIcon from '@/icons/iphone'
import {inject} from "vue";
const iosLink = inject('iosFotaTestLink')

</script>
