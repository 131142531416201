<template>
  <h4 class="text-2xl py-2">+31</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FID 머신러닝 기능이 오류로 인해 비공개에서 공개로 전환되어 있어 다시 비공개 처리 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+29</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>Direct Fota 인증서를 갱신 했습니다.</li>
  </ul>
</template>
