<template>
  <h4 class="text-2xl py-2">hotfix</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>[x] 1:1문의 작성시 이미지를 첨부할 수 있습니다. 애플리케이션 문제 또는 사용성개선에 관한 요청을 하시는 경우에 캡쳐를 함께 첨부 해 주시면 더욱 빠른 문제해결을 제공 해드릴 수 있습니다.</li>
    <li>[x] 서버상의 슬로우쿼리가 개선되었습니다. 이제 진단내역을 불러 올 때 시간이 확연히 줄어듭니다. 이 개선은 애플리케이션에서 나의 진단내역 보기를 호출하는 속도에도 영향을 미칩니다.</li>
    <li>[x] 슬로우쿼리가 개선되면서 진단내역이 10개씩 출력되던것을 한번에 30개씩 출력하도록 조정하였습니다.</li>
  </ul>

  <h4 class="text-2xl py-2">miner</h4>
  <li>[x] 로봇청소기의 r9 hexdump utility 바이너리 업로드기능이 추가되었습니다.</li>
  <li>[x] 로봇청소기의 프로토콜 구분자가 변경됩니다. (sshrk, sshr9, sshr9o, m9)</li>
  <li>[x] XML 파싱 예외 룰이 추가됩니다. (잘못 작성된 xml을 바로잡아줍니다)</li>
</template>
