<template>
  <div class="text-center pb-12">
  <p class="mx-auto mt-6 max-w-lg text-xl text-blackbox-bg text-opacity-90 sm:max-w-3xl">
    설치를 위해 OS를 선택 하세요.
  </p>
  </div>
  <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
    <div v-for="feature in features" :key="feature.name" class="pt-6">
      <a :href="feature.href">
        <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8">
          <div class="-mt-6">
            <div>
                  <span class="inline-flex items-center justify-center rounded-md bg-white border-blackbox-bg border p-3 shadow-lg">
                    <component :is="feature.icon" class="h-6 w-6 text-blackbox-bg" aria-hidden="true" />
                  </span>
            </div>
            <h3 class="mt-8 text-lg font-medium tracking-tight text-gray-900">{{ feature.name }}</h3>
            <p class="mt-5 text-base text-gray-500">{{ feature.description }}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router/dist/vue-router";
import iosIcon from '@/icons/iphone'
import aosIcon from '@/icons/android'
const router = useRouter()

const features = [
  {
    name: 'Android +13',
    description: '갤럭시 등 안드로이드 사용자를 위한 설치를 안내합니다. 안드로이드 13 (SDK 33)이상만 지원합니다.',
    icon: aosIcon,
    href: router.resolve({ name: 'lss_test_install_android' }).href
  },
  {
    name: 'iOS +16',
    description: '아이폰 테스트 위한 설치를 안내합니다. iOS 16버전 이상만 지원합니다.',
    icon: iosIcon,
    href: router.resolve({ name: 'lss_test_install_ios' }).href
  }
];
</script>
