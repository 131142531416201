<template>
  <div class="min-h-full">
    <div class="bg-blackbox-bg pb-32">
      <Disclosure as="nav" class="border-b border-blackbox-bg border-opacity-25 bg-blackbox-bg lg:border-none" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
          <div class="relative flex h-16 items-center justify-between lg:border-b lg:border-blackbox-bg lg:border-opacity-25">
            <div class="flex items-center px-2 lg:px-0">
              <router-link to="/" class="flex-shrink-0">
                <img :src="whiteIcon" class="block w-8 h-8" />
              </router-link>
              <div class="hidden lg:ml-10 lg:block">
                <div class="flex space-x-4">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-white bg-opacity-10 text-white' : 'text-white hover:bg-white hover:bg-opacity-5', 'rounded-md py-2 px-3 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                </div>
              </div>
            </div>
            <div class="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
              <div class="w-full max-w-lg lg:max-w-xs">
                <!-- search position -->
              </div>
            </div>
            <div class="flex lg:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-200 hover:bg-white hover:bg-opacity-10 hover:text-white">
                <span class="sr-only">Open main menu</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
            <div class="hidden lg:ml-4 lg:block">
              <div class="flex items-center">
                <!-- right side positiion -->
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel class="lg:hidden">
          <div class="space-y-1 px-2 pt-2 pb-3">
            <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-white bg-opacity-10 text-white' : 'text-white hover:bg-white hover:bg-opacity-5', 'block rounded-md py-2 px-3 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <header class="py-10">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight text-white">LSS 2.0</h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <slot />
        </div>
        <!-- /End replace -->
      </div>
    </main>


    <footer class="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="border-t border-gray-200 py-12 text-center md:flex md:justify-between">
        <p class="text-base text-gray-400">&copy; LG Electronics, Inc. All rights reserved.</p>
        <div class="mt-6 flex justify-center space-x-8 md:mt-0">
          <a v-for="(item, itemIdx) in social" :key="itemIdx" :href="item.href" class="inline-flex text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6" aria-hidden="true" />
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>


<script setup>
import whiteIcon from '@/assets/whiteIcon.png'
import redIcon from '@/assets/redicon.png'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import {defineComponent, h, defineProps, computed} from "vue";
import {useRouter} from "vue-router/dist/vue-router";
import ReleaseList from "@/release_notes"

const props = defineProps({
  activate : String,
});

const router = useRouter()
const latestVersion = computed(() => {
  let latest;
  for(let i in ReleaseList){
    if(ReleaseList[i].meta.type === 'install') continue;
    latest = ReleaseList[i];
    break;
  }
  return latest;
})

const navigation = [
  { name: '첫 화면', href: router.resolve({ name: 'home' }).href, current: props.activate === 'home' },
  { name: '설치하기', href: router.resolve({ name: 'releaseNote' }).href, current: props.activate === 'install' },
  { name: '릴리즈 노트', href: router.resolve({ name: latestVersion.value.name }).href, current: props.activate === 'releaseNote' },
  { name: '포타 설치하기', href: router.resolve({ name: 'fotaRelease' }).href, current: props.activate === 'fotaRelease' },
    //포타 분리앱이 배포되게 되면 이 주석을 풀면된다.
  // { name: '다이렉트 포타', href: router.resolve({ name: 'fotaRelease' }).href, current: props.activate === 'fotaRelease' },
]


const social = [
  {
    name: '',
    href: '#',
    icon: defineComponent({
      render: () =>
          h(
              'a',
              {
                href: "https://blackbox.lge.amuz.kr/",
                target: "_blank",
                class: 'flex'
              },
              [
                  h('img', { src: redIcon }),
                  h('div', {class : 'px-2 py-0.5 text-sm'}, "H&A Blackbox")
              ]
          )
    }),
  }
]
</script>
