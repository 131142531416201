<template>
  <h4 class="text-2xl py-2">+57 <span class="update_date">(2023.06.09)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 건조기 D3 Good 18kg 모델 추가 되었습니다. </li>
    <li>내장형 FOTA 건조기 D3 Good 19kg 모델 추가 되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+56 <span class="update_date">(2023.06.05)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 정수기 ATOM-U STS 모델 추가 되었습니다. </li>
    <li>내장형 FOTA 정수기 ATOM-4D+ 모델 추가 되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+55 <span class="update_date">(2023.05.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>블랙박스 모델 검색 시 검색이 되지 않는 현상을 수정 했습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+54 <span class="update_date">(2023.05.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA D3 good 모델이 추가되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+53 <span class="update_date">(2023.05.19)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 인증서가 갱신되었습니다. </li>
    <li>에어케어 제품군 네트워크 SSID가 수정되었습니다. </li>
    <li>에어케어 제품군 Wi-Fi 연결 시나리오가 수정되었습니다. </li>
    <li>슈케어 Wi-Fi 연결 시나리오가 수정되었습니다. </li>
    <li>광파오븐 Wi-Fi 연결 시나리오가 수정되었습니다. </li>
    <li>스탠드,벽걸이 에어컨 Wi-Fi 연결 시나리오가 수정되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+52 <span class="update_date">(2023.05.03)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 업데이트 시 다운로드, 업데이트 진행률을 각각 확인할 수 있도록 UI가 수정되었습니다. </li>
    <li>내장형 FOTA 로그 수집 값이 누락 되는 현상이 있어 수정되었습니다. </li>
  </ul>
</template>
