<template>
  <h4 class="text-2xl py-2">+16</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>[x] QR코드 촬영기능 호출 시, 카메라가 한번에 로드되지 않던 현상이 수정됩니다.</li>
    <li>[x] 로봇청소기 AP모드 진입안내 페이지가 추가됩니다. (로보킹,R9,M9 모델)</li>
    <li>[x] 로봇청소기의 일부 파싱룰이 개선됩니다.</li>
    <li>[x] 충전대 위치 파인더 추가</li>
    <li>[x] 포지션 각도 계산식 개선</li>
    <li>[x] 애니메이션 상에서 출력되는 x,y 위치 개선</li>
    <li>[x] 다음 이슈가 모두 해결되었습니다.</li>
  </ul>

  <h4 class="text-2xl py-2">+15</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>[x] 일부 Modal에서의 라우팅이 꼬이는 현상 및 페이지 이동시 창이 사라지지 않는 문제 등이 긴급수정되었습니다.</li>
  </ul>

  <h4 class="text-2xl py-2">+14</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>[x] 진단내역에서 하드웨어 백버튼이 사용되는 경우, SkinViewer에서 History가 존재하면 History를 우선해서 작동하고, History 가 없는경우 초기화면으로 돌아가는 안내팝업을 띄우로도록 조정됩니다.</li>
    <li>[x] 일부 모델에서 고객 동의 서명 이후 서명 Modal이 제거되지 않는 문제가 수정되었습니다.</li>
    <li>[x] 건조기 타워대응 SSID가 추가됩니다. ("LG_Smart_WashTower","LG_WashTower")</li>
    <li>[x] 쿠킹 시그니처 대응 SSID가 추가됩니다. ("Signature_DishWasher", "LG_Smart_DishWasher2", "Signature_DishWasher2")</li>
    <li>[x] 정수기 대응 스킨ID가 추가됩니다. (KWWN / KWWI)</li>
  </ul>

  <h4 class="text-2xl py-2">빌드버전 없음</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>[x] 이제 진단내역 화면 (스킨뷰어)에서 즉각 고장부분 등에 대해 사진을 촬영 바로 하고 업로드 할 수 있게 됩니다.</li>
    <li>[x] 진단내역 (스킨뷰어)의 로딩성능이 대폭 개선되었습니다. 불필요한 가이드 상세내용에 lazy-load가 적용되었고, 스킨에서 사용되지 않는 모든데이터가 서버로부터 제거된 상태로 호출됩니다. (평균 로딩시간 33.2초 → 12.7초로 개선)</li>
    <li>[x] 권한 요청체계가 모두 개선됩니다. 또한 설정 패키지가 모두 업데이트 되어 권한이 없는 경우 블랙박스 애플리케이션 권한설정으로 바로 이동할 수 있습니다.</li>
    <li>[x] 불필요한 라우트를 모드 정리하여, 애플리케이션 퍼포먼스가 더욱 좋아집니다.</li>
    <li>[x] 이제 1:1 문의 또는 개선요청 페이지에서 사진을 첨부할 수 있습니다.</li>
    <li>[x] 공지사항 내 리스트위젯 높이가 맞지않던 현상이 수정되고, 로딩 인디게이터가 중앙으로 자리잡습니다. (나의 진단내역/가이드 페이지 동일)</li>
    <li>[x] 애플리케이션 자동업데이트가 완전히 새로워집니다. (2.0.18부터) 이제 미디어 권한을 사용하지 않고도 업데이트 할 수 있도록 플로우가 변경됩니다.</li>
    <li>[x] SDK 업데이트로 인해 비활성화 된 QR코드기능을 복원하였습니다. 접근 패키지가 변경되었으며 권한에 대한 질의를 카메라 활성화 단계에서 하도록 변경하였습니다.</li>
    <li>[x] 스플래시화면이 완전히 새롭게 빌드되었습니다. 이제 가로모드가 지원되고, 초기화 단계에서의 모든 작업을 실시간으로 스트림하여 메세지를 전달 해 줍니다.</li>
    <li>[x] 가로모드로 변경시 애플리케이션이 초기화 되는 문제가 수정되었습니다.</li>
    <li>[x] 냉장고 아이스메이커 스킨, 김치냉장고 스킨이 추가되었습니다.</li>
  </ul>
</template>
