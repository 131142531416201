<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M1652 5108 c-7 -7 -12 -21 -12 -32 0 -12 49 -111 110 -221 60 -109
  107 -202 105 -206 -2 -3 -40 -26 -83 -52 -260 -151 -478 -416 -572 -695 -35
  -105 -60 -241 -60 -328 l0 -64 1420 0 1420 0 0 53 c0 81 -28 233 -61 331 -97
  290 -303 542 -577 707 -45 28 -76 52 -74 60 2 8 51 100 109 206 63 117 103
  201 101 214 -4 30 -33 47 -55 33 -10 -6 -68 -103 -130 -215 -64 -116 -118
  -203 -125 -202 -7 1 -58 17 -113 37 -314 110 -672 110 -990 -1 -55 -19 -106
  -35 -113 -36 -8 -1 -59 81 -121 195 -59 108 -113 203 -120 212 -16 19 -42 21
  -59 4z m321 -860 c24 -13 57 -68 57 -96 0 -37 -28 -86 -59 -105 -117 -69 -235
  87 -140 183 40 39 89 45 142 18z m1303 -5 c23 -15 42 -48 45 -78 4 -49 -3 -69
  -35 -101 -26 -26 -42 -34 -71 -34 -48 0 -91 26 -110 66 -21 44 -19 75 8 115
  37 55 107 69 163 32z"/>
  <path d="M594 3440 c-66 -26 -139 -97 -172 -168 l-27 -57 0 -730 0 -730 27
  -57 c113 -242 444 -252 570 -17 l23 44 0 760 0 760 -22 41 c-29 55 -95 118
  -151 146 -66 32 -178 35 -248 8z"/>
  <path d="M4293 3439 c-61 -23 -131 -88 -165 -150 l-23 -44 0 -760 0 -760 22
  -41 c127 -237 457 -229 571 14 l27 57 0 730 0 730 -33 67 c-74 150 -244 217
  -399 157z"/>
  <path d="M1150 2345 c0 -1054 0 -1054 21 -1112 27 -69 94 -145 164 -181 47
  -25 60 -27 213 -30 l162 -4 1 -101 c1 -56 4 -235 8 -397 6 -294 7 -295 33
  -345 34 -64 90 -118 153 -147 71 -32 191 -32 257 0 65 32 125 94 156 160 l27
  57 3 388 3 387 209 0 209 0 3 -387 3 -388 27 -57 c31 -66 91 -128 156 -160 69
  -34 186 -33 263 3 61 29 121 85 150 140 32 63 39 154 39 506 l0 341 163 4
  c152 3 165 5 212 30 70 36 137 112 164 181 21 58 21 58 21 1112 l0 1055 -1410
  0 -1410 0 0 -1055z"/>
  </g>
  </svg>
</template>
