<template>
  <h4 class="text-2xl py-2">miner</h4>
  <li>* 현재 릴리즈버전으로, 베타테스트 페이지에서 다운로드받을 수 있습니다.</li>
  <li>* 릴리즈버전을 설치하는 경우 향후 업데이트가 정식배포될 때 릴리즈버전을 완전히 삭제하고 설치해야 합니다.</li>
  <li>[x] 이제 모델명 입력화면에서 돋보기를 켜고 끌 수 있습니다.</li>
  <li>[x] 이제 베타버전도 APK배포 페이지에서 배포됩니다.</li>
  <li>[x] 챗봇이 추가되었습니다.</li>

  <h4 class="text-2xl py-2">hotfix</h4>

  <li>[x] 메세지가 길어질때 메세지박스가 깨지는 현상이 수정되었습니다.</li>
  <li>[x] 챗봇에서 액션(답장)이 제공된 후 스크롤이 튀는현상이 수정되었습니다.</li>
  <li>[x] 차트레이블을 그룹화하거나 싱글화 할 수 있도록 조정되었습니다.</li>

  <li>[x] 나의 진단이력에서 진단내역보기을 보던 중 뒤로가면 첫화면으로 돌아가는 현상이 수정되었습니다.</li>

  <li>[x] 진단결과 화면에서 이제 하드웨어 백버튼(안드로이드)외에도 상단의 뒤로가기를 누를 때에도 돌아가기 안내가 출력됩니다.(전기레인지 요청)</li>

  <li>[x] 진단내역 그만보기 안내의 UI가 수정됩니다. (정현호책임 요청)</li>

  <li>[x] 나의 진단내역보기 수정</li>

  <li>[x] 타이틀이 일부기기의 헤더길이 문제로 “뒤로" 로 변경됩니다.</li>
  <li>[x] 리스트 아이템 갯수가 많아지는 경우 높이가 맞지않는 현상이 수정되었습니다.</li>
  <li>[x] 카테고리, 시리얼출력 대신 모델명 - 시리얼 / 카테고리 출력으로 변경됩니다.</li>
  <li>[x] Wifi 연결시나리오가 전면 개정되었습니다.</li>

  <li>[x] 제품별 AP모드 활성화 안내페이지가 추가됩니다.</li>
  <li>[x] 페이지네이션이 포함되는 제품군에 프로그레스 인디게이터가 추가되었습니다.</li>
  <li>[x] 이제 데이터 수신 도중 끊어지면 즉각적으로 재시도할 수 있습니다.</li>
  <li>[x] 데이터 수신시 프로그레스바가 초기화되는 문제가 수정되었습니다.</li>
  <li>[x] 이제 데이터 수신 중 수집이 완료된 후에 서버등록에 실패하면 데이터를 재수집 하지않고, 전송만 재시도할 수 있도록 개선됩니다.</li>
  <li>[x] 이제 데이터네트워크가 아닌 상태에서도 서버에 진단수집결과를 전송할 수 있게 됩니다. 제품과 연결이 해제되고, 안정적인 네트워크에 연결된 후 데이터전송을 시도할 수 있게 됩니다.</li>
  <li>[x] 제품 연결모드 안내시 WIFI가 해제되기 전에 연결 안내가 출력되던 문제가 수정되었습니다.</li>
  <li>[x] 데이터 수집이 완료된 후 서버로 전송 전 WIFI 연결 해제안내가 출력되지 않던 문제가 수정되었습니다.</li>
  <li>[x] 제품과의 연결을 기다리는 화면 및 연결 해제를 기다리는 화면에서 Wifi 설정 State로 이동할 수 있도록 버튼이 추가됩니다. * 이 옵션은 연결 및 해제 씬에서 직접 wifi를 해제하는 사람과 설정창이 바로 열리는 사람의 선호도가 나뉘어 결정된 사항입니다.</li>
  <li>[x] 이제 데이터가 서버로 전송 될 때 전송 중복이 되지 않도록 버튼이 사라지고 인디게이터가 출력됩니다.</li>
  <li>[x] 냉장고 및 전기레인지 제품의 연결안내가 수정됩니다.</li>
  <li>[x] 스킨일부가 변경됩니다.</li>

  <li>[x] 스킨뷰어에 뒤로버튼이 이중으로 출력되는 현상이 수정되었습니다.</li>
  <li>[x] 쿠킹 → 레인지 스킨에 일부 키가 발견되지 않으면 차트가 가려지도록 조정됩니다.</li>
  <li>[x] 냉장고 스킨 중 가이드버튼이 드래그 가능하도록 조정됩니다.</li>
  <li>[x] 냉장고 스킨 중 데이터 표시항목 버튼의 디자인이 일부 개선되었습니다.</li>
  <li>[x] 냉장고 스킨 중 차트의 툴팁사용 여부를 토글할 수 있도록 기능이 추가되었습니다.</li>
  <li>[x] CMS 기능이 추가되었습니다.</li>

  <li>[x] 이제 진단내역/모델리스트/파싱/로직 등의 모든 Document를 검색결과에따라 CSV로 저장할 수 있게 됩니다.</li>
  <li>[x] 회원목록을 그룹별로 조회하고 CSV로 저장할 수 있게 됩니다.</li>
  <li>[x] 관리자화면에 ‘챗봇빌더' 가 추가되었습니다. 곧 프론트 스킨이 준비되면 일부 예제를 세팅하여 사용방법에 대한 가이드를 배포하겠습니다.</li>
  <li>[x] 구형기기의 일부 버그가 수정됩니다.</li>

  <li>[x] v20 (androd v9 / 2019년 5월1일 보안패치 / 커널 3.18.120 기준) 에서 진단내역이 호출되지 않는 문제(선언되지 않은 파라미터를 허용하지 않는 문제)가 개선되었습니다.</li>
  <li>[x] v30 (android v9 / 2019년 9월1일 보안패치 / 커널 4.4.153 기준) 에서 3항연산자가 제대로 작동하지 않아 탭위치변경이 불가능한 현상이 개선되었습니다.</li>
  <li>[x] 케어매니저 모드가 추가됩니다.</li>

  <li>[x] 계정 생성시 ‘케어매니저'를 선택할 수 있습니다. (차후 CSMS연동 예정)</li>
  <li>[x] 케이매니저 > ‘건조기’ 스킨이 추가됩니다.</li>
</template>
