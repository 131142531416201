<template>
  <AppLayout :activate="'home'">
  <div class="bg-white">
    <main class="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0 pt-16">
        <img class="mx-auto h-12 w-auto" :src="redIcon" alt="HnA Blackbox 2" />
      </div>
      <div class="mx-auto max-w-xl py-16 sm:py-24">
        <div class="text-center">
          <p class="text-base font-semibold text-indigo-600">{{ latestVersion.meta.date }}</p>

          <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {{ latestVersion.meta.ver }}
            <small class="text-2xl text-blue-700">
              +{{ latestVersion.meta.build }}
            </small>
          </h1>
          <p class="mt-2 text-lg text-gray-500">새 버전을 다운로드할 수 있습니다.</p>

          <a :href="downloadLink" class="mt-6 inline-flex items-center rounded-md border border-transparent bg-blackbox-bg bg-opacity-90 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blackbox-bg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <ArchiveBoxArrowDownIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            지금 설치하기
          </a>
          <a :href="latestLink" class="mt-6 ml-2 inline-flex items-center rounded-md border border-blackbox-bg px-6 py-3 text-base font-medium text-blackbox-bg shadow-sm">
            <BookOpenIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            변경사항 확인하기
          </a>
        </div>
        <div class="mt-12">
          <h2 class="text-base font-semibold text-gray-500">다른 도움이 필요하세요?</h2>
          <ul role="list" class="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
            <li v-for="(link, linkIdx) in links" :key="linkIdx" class="relative flex items-start space-x-4 py-6">
              <div class="flex-shrink-0">
                <span class="flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-50">
                  <component :is="link.icon" class="h-6 w-6 text-indigo-700" aria-hidden="true" />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <a :href="link.href" class="focus:outline-none">
                  <h3 class="text-base font-medium text-gray-900">
                    <span class="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
                      <span class="absolute inset-0" aria-hidden="true" />
                      {{ link.title }}
                    </span>
                  </h3>
                  <p class="text-base text-gray-500">{{ link.description }}</p>
                </a>
              </div>
              <div class="flex-shrink-0 self-center">
                <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
  </AppLayout>
</template>
<script setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { PhoneIcon, BookmarkSquareIcon, BookOpenIcon, ArchiveBoxArrowDownIcon } from '@heroicons/vue/24/outline'
import AppLayout from '@/components/AppLayout'
import redIcon from '@/assets/redicon.png'
import {computed, inject} from "vue";
import ReleaseList from "@/release_notes";
import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter()


const latestVersion = computed(() => {
  let latest;
  for(let i in ReleaseList){
    if(ReleaseList[i].meta.type === 'install') continue;
    latest = ReleaseList[i];
    break;
  }
  return latest;
});
const latestLink =  router.resolve({ name: latestVersion.value.name }).href;
const links = [
  { href: "javascript:alert('준비중입니다.')", title: '가이드', description: '설치가이드 및 애플리케이션 사용에 관한 가이드를 확인하세요.', icon: BookmarkSquareIcon },
  { href: latestLink, title: '릴리즈노트', description: '업데이트 내역을 안내 해 드립니다.', icon: BookOpenIcon },
  { href: "tel:+82218335770", title: '전화로 문의하기', description: '다른 도움이 필요하면, 여기를 눌러 전화로 문의할 수 있습니다.', icon: PhoneIcon },
]

const downloadLink = inject('downloadLink')
</script>
