<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
   preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M3385 5105 c-353 -78 -692 -407 -799 -779 -27 -92 -42 -233 -32 -290
    l7 -36 73 0 c239 0 514 162 674 397 124 183 202 429 202 639 l0 84 -32 -1
    c-18 0 -60 -7 -93 -14z"/>
    <path d="M3305 3933 c-134 -17 -252 -52 -549 -163 -164 -61 -167 -61 -346 2
    -413 143 -503 164 -660 154 -315 -21 -634 -169 -866 -400 -348 -349 -493 -919
    -389 -1531 109 -644 425 -1330 779 -1693 153 -157 286 -242 440 -282 163 -41
    295 -22 631 93 233 79 243 81 330 81 109 -1 177 -18 354 -88 204 -81 244 -90
    401 -90 128 0 140 1 219 32 123 47 221 113 344 231 225 218 445 565 600 946
    62 152 70 181 63 220 -10 53 -49 90 -187 181 -284 187 -407 376 -439 679 -25
    230 32 446 164 621 60 80 159 173 287 269 57 43 106 79 107 81 2 1 -29 51 -69
    111 -205 303 -500 482 -884 538 -92 13 -258 17 -330 8z"/>
    </g>
  </svg>
</template>
