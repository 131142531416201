<template>
  <h4 class="text-2xl py-2">hotfix2</h4>
  <li>[x] 애플리케이션 패키지네임이 변경됩니다. (com.lge.blackbox → com.lge.hnablackbox)</li>
  <li>[x] 시리얼넘버가 3자리까지 직접입력할 수 있게 되고, QR코드촬영시 앞 3자리만 잘라서 사용하도록 수정됩니다.</li>
  <li>[x] 진단내역 공유시 도메인이 사라지는 문제가 수정됩니다. (CORS)</li>
  <li>[x] 진단내역에 pull refresh 및 infinity scroll 기능이 추가됩니다. (당겨서 내역을 새로 고치거나 가장 하단에 도착하면 페이지가 계속 추가로드됩니다.)</li>
  <li>[x] 개선요청사항 게시판이 추가됩니다. 더보기 페이지에서 확인할 수 있습니다.</li>
  <li>[x] 제품 진단데이터 전송 중 dialog 레이어터치시 종료되는 문제가 수정되었습니다.</li>
  <li>[x] skin table에 value 출력 → result 출력으로 변경</li>

  <h4 class="text-2xl py-2">hotfix1</h4>
  <li>[x] Android 카메라 권한 문제가 수정되었습니다.</li>
  <li>[x] 릴리즈버전의 권한이 추가되어 iOS 연결 문제가 수정되었습니다.</li>
  <li>[x] 스킨 - 제안하기탭이 점검사항 으로 변경되었습니다.</li>
  <li>[x] 스킨 헤더가 이중으로 출력되던 문제를 수정했습니다.</li>
  <li>[x] 애플리케이션 연결모듈에 이제 카테고리가 추가되었습니다. 레거시 카테고리에 추가하면 애플리케이션 메인화면에 앱이 등록됩니다.</li>
  <li>[x] 하드웨어 백버튼(안드로이드)을 진단내역 화면에서 누르면 이제 Confirm 을 통해 창을 닫을지 물어봅니다.</li>
  <li>[x] 데이터 수신 진행율이 퍼센트로 변경 됩니다.</li>
  <li>[x] 이제 개인진단이력 을 확인할 수 있습니다. 슈퍼관리자 권한을 가진 경우 모든 이력이 출력됩니다.</li>
  <li>regacy app schme id 확보 → 처리완료</li>

  <h4 class="text-2xl py-2">minor</h4>
  <li>이제 Dev 표기가 삭제됩니다.</li>
  <li>곧 iOS에서 애플리케이션을 사용할 수 있습니다. 엔터프라이즈 배포가 완료되었습니다.</li>
  <li>현재 스킨 헤더가 2중으로 나오게 됩니다. 이 문제는 곧 서버에서 동기화하여 해결하겠습니다.</li>
  <li>Wifi 시나리오 변경이 아직 이루어지지 못했습니다. 하지만 일부 예외처리가 추가되었습니다.</li>

  <h4 class="text-2xl py-2">서버 변경사항</h4>
  <li>[x] 파싱빌더 : Cycle 에 Indicator Key 옵션이 추가되었습니다.</li>
  <li>[x] 파싱빌더 : Cycle Reverse 옵션이 추가되었습니다.</li>
  <li>[x] 파싱/로직 : 불필요한 language key가 초기화되어 앱 실행속도가 개선됩니다.</li>
  <li>[x] 파싱빌더 엑셀 업로드 할 떄 namespace 가 raw_data 가 들어가지 않는 문제가 수정되었습니다.</li>
  <li>[x] 로직 excel 업로드 할 때 $parent_type 기준으로 구분하도록 수정되고 일부 구조가 변경되었습니다.</li>
  <li>[x] 레거시 앱 등록 모듈이 개발되어 추가되었습니다.</li>

  <h4 class="text-2xl py-2">스킨 변경사항</h4>
  <li>[x] 이제 에러코드에 가이드링크 링크 적용 및 에러 강조색상 변경이 적용됩니다. 로직빌더에서 isError 가 Active된 항목은 강조색상이 적용됩니다.</li>
  <li>[x] 이제 조치 가이드에 카테고리 뎁스가 적용 됩니다. 카테고리는 최대 4단계까지 지원합니다.</li>
  <li>[x] 가이드 상세내용의 테이블,리스트가 스타일링 되었습니다.</li>
  <li>[x] 가이드 목록에서 생성된 가이드가 한개면 상세페이지로 바로 이동됩니다.</li>
  <li>[x] 가이드필터시 show index 어긋나는 문제가 수정되었습니다.</li>
  <li>[ ] 제안하기→점검사항 으로 변경 (처리중입니다)</li>
  <li>[] 스킨 헤더 미리보기 -> 애플리케이션 내부 로 이동 (처리중입니다)</li>

  <h4 class="text-2xl py-2">애플리케이션 변경사항</h4>
  <li>[x] 이제 스플래시 스크린에 버전이 표기됩니다.</li>
  <li>[x] 이제 스플래시화면에서 처리중인 데이터 내용이 표기되고, 인디게이터가 출력됩니다.</li>
  <li>[x] 모델명 싱크가 제거되었습니다. 이 작업은 실행속도에 영향을 미칩니다.</li>
  <li>[x] 개발파일을 상당수 제거하여 용량 및 실행속도를 최적화 하였습니다.</li>
  <li>[x] 모델명 자동완성기능을 제거하고, 잘못된 모델명 입력시 미지원 안내가 출력됩니다.</li>
  <li>이 작업은 QR코드 모드에도 동시적용 되었지만 더 충분한 테스트가 필요합니다.</li>
  <li>[x] 더 보기가 페이지 구현 되었습니다. 구현 사항은 아래와 같습니다.</li>
  <li>[x] 공지사항추가</li>
  <li>[x] 앱 사용 가이드 서버연동 추가</li>
  <li>[x] 버전표기</li>
  <li>[x] 2weeks@amuz.co.kr로 표시되던 부분들이 모두 실제 로그인 정보로 변경되었습니다.</li>
  <li>[x] 서버에 등록된 약관이 연동되어 출력됩니다.</li>
  <li>[x] 약관 체크박스의 자동체크가 해제되었습니다.</li>
  <li>[x] route 오류를 방지하기 위해 점검하기 화면에서 bottom bar가 제거되었습니다.</li>
  <li>[x] 앱 아이콘과 정식명칭이 적용되었습니다.</li>
  <li>[x] 이제 진단내역에 모뎀에서 수집된 정보가 함께 저장됩니다. (JSON Viewer 적용이 필요합니다.)</li>
  <li>[x] 이제 진단내역을 공유할 수 있습니다. 진단내역 화면에서 우측 상단의 공유 아이콘을 선택 해 보세요.</li>
  <li>[x] 이제 진단내역 화면에서 hardware backbutton 이 무효화 처리됩니다. 뒤로 나가려면 좌측 상단의 leadbutton 을 탭 해야 합니다.</li>
  <li>[x] 이제 첫화면에 있는 레거시 앱 링크가 서버에 추가된 레거시 앱 모듈과 연동됩니다. 등록된 레거시 앱의 아이콘이 없는경우 나타나지 않으며 앱이 실행되려면 Scheme 을 등록해야 합니다.</li>
</template>
