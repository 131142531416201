<template>
  <h4 class="text-2xl py-2">+81 <span class="update_date">(2024.07.10)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FX HP 건조 불만 개선 내장형 FOTA PGM 추가되었습니다.</li>
    <li>WT 24 누수 개선 내장형 FOTA PGM 추가되었습니다.</li>
    <li>RX Styler Display SW Block 처리되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+80 <span class="update_date">(2024.07.03)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>지그 정수기 항목 추가되었습니다.</li>
    <li>23 Victoria 내장형 FOTA PGM 추가되었습니다. [SAA44296901, SAA44608101, SAA44608601]</li>
    <li>F-Ardor 내장형 FOTA PGM 추가되었습니다. [SAA43871501, SAA44765001]</li>
    <li>하이드로타워 내장형 FOTA PGM 추가되었습니다. [SAA44378602, SAA44378102, SAA44378502]</li>
  </ul>
  <h4 class="text-2xl py-2">+79 <span class="update_date">(2024.06.13)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>D3 22kg 건조기 포말림 개선 PGM 4건 추가되었습니다.</li>
    <li>RX 스타일러 스티머 및 의류낙하 개선 PGM 3건 추가되었습니다.</li>
  </ul>
</template>
<script setup>
</script>
