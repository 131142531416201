import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Home";
import ReleaseNote from "@/components/Release"
import ReleaseList from "@/release_notes"
import FotaInstall from "@/components/FotaInstall"
import FotaInstallHome from "@/components/FotaInstallHome";
import FotaInstallAndroid from "@/components/FotaInstallAndroid";
import FotaInstallIOS from "@/components/FotaInstallIOS";
import LssInstallTest from "@/components/LssInstallTest"
import LssInstallHomeTest from "@/components/LssInstallHomeTest";
import LssInstallAndroidTest from "@/components/LssInstallAndroidTest";
import LssInstallIOSTest from "@/components/LssInstallIOSTest";
import FotaInstallTest from "@/components/FotaInstallTest"
import FotaInstallHomeTest from "@/components/FotaInstallHomeTest";
import FotaInstallIOSTest from "@/components/FotaInstallIOSTest";

const routes = [
    { name: "home", path: '/', component: Home },
    { name: "releaseNote",
        path: '/release/',
        component: ReleaseNote,
        children: ReleaseList
    },
    {
        name: "fotaRelease",
        path: '/fota/release',
        component: FotaInstall,
        children: [
            {
                name: 'fota-install-home',
                path: '',
                component : FotaInstallHome,
            },
            {
                name: 'fota_install_ios',
                path: 'ios',
                component: FotaInstallIOS
            },
            {
                name: 'fota_install_android',
                path: 'android',
                component: FotaInstallAndroid
            },
        ]
    },
    {
        name: "lssTest",
        path: '/lss/test',
        component: LssInstallTest,
        children: [
            {
                name: 'lss-test-install-home',
                path: '',
                component : LssInstallHomeTest,
            },
            {
                name: 'lss_test_install_android',
                path: 'android',
                component: LssInstallAndroidTest
            },
            {
                name: 'lss_test_install_ios',
                path: 'ios',
                component: LssInstallIOSTest
            }
        ]
    },
    {
        name: "fotaTest",
        path: '/fota/test',
        component: FotaInstallTest,
        children: [
            {
                name: 'test-install-home',
                path: '',
                component : FotaInstallHomeTest,
            },
            {
                name: 'test_install_ios',
                path: 'ios',
                component: FotaInstallIOSTest
            }
        ]
    }
    ];

export default createRouter({
    history : createWebHistory(),
    routes: routes
});
