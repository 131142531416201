<template>
  <h4 class="text-2xl py-2">2.0.9</h4>
  <li>이제 iOS에서 애플리케이션을 사용할 수 있게 됩니다.</li>
  <li>iOS는 Wifi 상태감지를 지원하지 않으므로 Wifi 수동연결 안내가 나올때 반드시 제품과 연결을 미리 진행하시고, 데이터 전송시도가 뜰 때 wifi를 종료해야합니다.</li>
  <li>아래 사용 영상을 반드시 미리 확인 부탁드립니다.</li>

  <h4 class="text-2xl py-2">해결된 문제 및 개선사항</h4>
  <li>[x] 1:1문의에 현재 모든 글이 보이는 것은 해당 계정이 최고 관리자이기 때문입니다.</li>
  <li>[x] 이제 진단정보를 제품으로부터 전송 할 때 모뎀의 정보가 함께 저장됩니다.</li>
  <li>[x] 1:1 문의시 로그인창이 뜨는 문제가 해결되었습니다.</li>
  <li>[x] 1:1 문의시 하드웨어 백버튼 동작루틴이 개선되었습니다. (안드로이드 뒤로가기)</li>
  <li>[x] "더 보기"에서 하드웨어 back button 누르면 바로 나가 버립니다. "종료 하시겠습니까?" 라는 문의가 필요합니다.</li>
  <li>[x] 블랙박스 웹사이트에서 애플리케이션 사용가이드 및 공지사항을 등록할 수 있게 됩니다. 온라인 에디터를 사용하므로 PPT,PDF,이미지,영상 등을 자유롭게 첨부할 수 있습니다.</li>
  <li>[x] 더보기 하단 및 스플래시 화면의 버전표기에 Beta 문구가 추가 되었습니다.</li>
  <li>[x] IOS 업데이트 배포 해 줄것</li>
  <li>[x] iOS에서 NSLocalNetworkUsageDescription 을 Info.plist 에서 추가하여 왜 해당권한을 필요로하는지 설명하지 않으면 권한을 허가 해주지 않는 보안패치가 최근에 있었고, 이로인해 릴리즈버전에서 통신이 안되는 문제가 지속해서 발생하였습니다. 추가된 키 디스크립션은 아래와 같습니다.
  <key>NSCameraUsageDescription</key> <string>QR코드 인식을 위해 카메라 기능에 접근합니다.</string> <key>NSFileProviderDomainUsageDescription</key> <string>애플리케이션 업데이트를 위해 파일 저장소를 활용합니다.</string> <key>NSLocalNetworkUsageDescription</key> <string>제품 연결을 위해 Wifi 네트워크를 사용합니다.</string> <key>NSBonjourServices</key> <string>디버그 및 프로필 구성을 위해 사용됩니다.</string></li>
  <li>[x] 최근 점검이력 탭이 최근 동작이력으로 명칭이 변경되었습니다.</li>
  <li>[x] 파싱빌더에서 청소기 23.4V 처럼 소수점 반영을 위한 수식이 사용 가능하도록 업데이트 되었습니다.</li>
  <li>[x] 앱에서 차트 가로축의 텍스트가 줄어드는 문제가 개선되었습니다.</li>
  <li>[x] 조치가이드 내에 링크가 감지되는 경우 외부링크 감지되면 고객에게 공유 또는 링크로 이동 confirm 창이 뜹니다.</li>
  <li>[x] 진단 결과화면에 토끼 GIF 이미지 대신 각 사업부별 제품 아이콘이 모두 적용되었습니다.</li>
</template>
