<template>
  <h4 class="text-2xl py-2">+64 <span class="update_date">(2023.08.30)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>한글, 영어 언어 선택 기능이 추가 되었습니다.</li>
    <li>상냉하동 배수구 결빙 이슈 대응 FOTA 모델이 추가 되었습니다. </li>
    <li>냉장고 무드업 기능이 추가 되었습니다. </li>
    <li>홈 페이지에 냉장고 무드업 버튼이 추가 되었습니다. </li>
    <li>홈 페이지에 서비스 전,후 제품 상태 확인 버튼이 제거 되었습니다.</li>
    <li>공기청정기 모델 Wi-Fi UUID, Wi-Fi 연결 안내 페이지가 수정되었습니다.</li>
    <li>FOTA 인증서가 갱신 되었습니다.</li>
    <li>MCS/CSMS 연동 기능 사용하면 무조건 메인 홈페이지로 이동하도록 변경되었습니다.</li>
  </ul>
</template>
