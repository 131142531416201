<template>
  <h4 class="text-2xl py-2">+17</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>모델 조회 시 로딩 아이콘이 나오고, 조회하기 버튼이 여러번 눌리는 현상을 수정 했습니다.</li>
    <li>고객서명 시 사인이 범위 밖으로 벗어나는 현상을 수정했습니다.</li>
    <li>블랙박스 앱에서 노이즈 앱 실행시 앱이 설치되어 있음에도 불구하고 계속 다운로드 팝업창만 띄우던 현상을 수정했습니다.</li>
    <li>로봇 청소기 청소시간이 NaN분으로 출력되던 현상을 수정했습니다.</li>
    <li>로봇 청소기 PLAY 버튼의 배열이 맞지 않던 현상을 수정했습니다.</li>
    <li>로봇 청소기 SW 버젼 글자가 너무 커 두줄로 보이던 현상을 수정했습니다.</li>
    <li>로봇 청소기 최근 동작이력의 항목 이름 수정 했습니다.</li>
    <li>로봇 청소기 지도 배율을 최소로 할때 지도가 화면에서 사라지던 현상을 수정해습니다.</li>
    <li>로봇 청소기 지도 자동 스케일을 조정했습니다.</li>
    <li>정수기 UI 스킨 [LG_Smart_Water2] 을 추가 했습니다.</li>
  </ul>
</template>
