<template>
  <h4 class="text-2xl py-2">2.0.10</h4>
  <li>부가기능에서의 앱설치 등을 지원하도록 애플리케이션이 개선되었습니다. 이 기능은 다운로드하여 재설치 해야합니다.</li>

  <h4 class="text-2xl py-2">스킨패치</h4>
  <li>이 기능은 별도의 패치를 하지 않아도 적용됩니다.</li>
  <li>[x] 조치가이드의 분류가 관리자 화면에서의 정렬순서와 일치하도록 수정되었습니다.</li>
  <li>[x] 리빙 요청 : 점검사항에서 에러코드가 상단에 배치되도록 조치가이드 카테고리 조정 필요함(원하는 배치에 따라) →</li>
  <li>[x] 조치가이드의 실제 문서목록이 제목 오름차순으로 정렬됩니다.</li>
  <li>[x] 에어솔루션 요청 : 가이드 목록이 제목순으로 정렬되어야 함</li>
  <li>[x] 사업부 카테고리에 생성된 기타 카테고리(공지사항, 프로그램다운 등)들을 모두 삭제하였습니다. 대신, 부가기능이 2뎁스를 지원하도록 설계가 변경되었습니다. 부가기능 분류 가 별도로 생성되어 있으니 관리자 패널에서 확인 부탁드립니다.</li>
  <li>[x] 부가기능이 추가되었습니다.</li>
  <li>[x] 점검사항 및 부가기능을 활성화 한 상태에서 한번 더 누르면 index에서 list로 넘어가지 않던 문제가 수정되었습니다.</li>
  <li>[x] 최근 동작이력에서 점검사항으로 연결되는 경우, 가이드 상세페이지까지 진입한 후에 뒤로 나오면 필터된 목록이 풀리면서 가이드리스트가 초기화되는 문제가 수정되었습니다.</li>
  <li>[x] 검색기능이 추가되었습니다.</li>
</template>
