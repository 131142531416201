<template>
  <h4 class="text-2xl py-2">+32</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>DirectFOTA 동작시 LOG가 작성 기능이 추가되었습니다. 동작 감지 시 LOG가 서버에 기록됩니다.</li>
    <li>DirectFOTA 워시타워 1모뎀 관련 대응 완료 했습니다.</li>
    <li>CSMS 특정 정수기 모델명 감지 시 DirectFOTA로 진입하도록 수정했습니다.</li>
    <li>DirectFOTA 세탁기 테스트 모델 Hex 추가 했습니다.</li>
    <li>DirectFOTA WiFi 모듈 패키지가 변경되었습니다.</li>
  </ul>
</template>
