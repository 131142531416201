<template>
  <h4 class="text-2xl py-2">+51 <span class="update_date">(2023.04.19)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 인증서를 갱신 했습니다. </li>
    <li>블랙박스 회원가입 버튼이 제거되었습니다.</li>
    <li>정수기 Wi-Fi 연결 안내 문구 수정했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+50 <span class="update_date">(2023.03.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 인증서를 갱신 했습니다. </li>
    <li>블랙박스 앱 홈페이지 에어컨 FOTA 버튼이 제거 되었습니다. </li>
    <li>내장형 FOTA 휘센 에어컨 모델이 제거 되었습니다.</li>
    <li>블랙박스 앱 메인 화면이 개선 되었습니다.</li>
    <li>에어케어 에어로타워 Wi-Fi 연결 시나리오 이미지가 수정 되었습니다.</li>
    <li>에어케어 제습기 Wi-Fi 연결 시나리오가 추가 되었습니다.</li>
    <li>에어케어 에어로퍼니처 Wi-Fi 연결 시나리오가 추가 되었습니다.</li>
    <li>에어케어 공기청정기 360 플러스 Wi-Fi 연결 시나리오가 추가 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+49 <span class="update_date">(2023.02.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 인증서를 갱신 했습니다. </li>
    <li>내장형 FOTA 지원 모델이 추가되었습니다. 휘센타워 에어컨(SAA42310915)모델 사용이 가능합니다. </li>
    <li>내장형 FOTA 스탠드 에어컨 Wi-Fi 연결 안내가 추가 되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+48 <span class="update_date">(2023.02.21)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA ATOM-V (SAA43766101, SAA43766201) 모델이 추가 되었습니다. </li>
  </ul>
  <h4 class="text-2xl py-2">+47 <span class="update_date">(2023.02.21)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FX 모델 FOTA 업데이트 이상이 확인되어 일시적 비활성화 했습니다.</li>
    <li>모뎀 FOTA 미지원 버전 안내 출력 메시지를 수정했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+46 <span class="update_date">(2023.02.16)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>워시타워 SAA43701802 모델 대상 체크썸 수정 했습니다.</li>
    <li>모뎀포타 사용중 동작로그를 기록 하도록 추가 했습니다.</li>
    <li>내장형 FOTA 사용중 음성모뎀 감지시 미지원 모델 안내 하도록 수정 했습니다.</li>
    <li>식기세척기 QR코드 위치 설명 내용 수정</li>
  </ul>
  <h4 class="text-2xl py-2">+45 <span class="update_date">(2023.02.14)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA T10 모델 업데이트에 오류가 발생되어 T10 모델에 대한 업데이트 지원을 일시적으로 중단 했습니다.</li>
    <li>지원되지 않는 Modem 일 경우 출력되는 안내 문구가 수정 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+44 <span class="update_date">(2023.02.09)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 하나의 항목씩 업데이트가 진행 되도록 수정 되었습니다. Main 항목이 우선적으로 업데이트 됩니다.</li>
    <li>업데이트 도중 FOTA가 종료될 경우 로그가 저장되지 않던 문제를 수정 했습니다.</li>
    <li>업데이트 진행률이 100%가 되면 업데이트 완료 처리를 하도록 수정 했습니다.</li>
    <li>업데이트 진행중 전체 리스트가 출력 시키던 것에서 업데이트 대상만 리스트에 보이도록 수정 했습니다.</li>
    <li>내장형 FOTA D3 워시타워, FX 모델 (LCD)이 재등록 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+43 <span class="update_date">(2023.01.31)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA D3 워시타워, FX 모델 (LCD)에 문제를 확인하여 제외 처리 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+42 <span class="update_date">(2023.01.30)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA V+ LCD 바이너리 버전을 v19 -> v20으로 갱신했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+41 <span class="update_date">(2023.01.27)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 인증서를 갱신했습니다.</li>
    <li>내장형 FOTA D3 워시타워, FX 모델 추가 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+40 <span class="update_date">(2023.01.17)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 워시타워 버튼 텍스트 수정</li>
    <li>내장형 FOTA SAA43909702 모델 추가 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+39</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>내장형 FOTA 인증서를 갱신했습니다.</li>
    <li>모델 검색시 로딩표시가 출력되지 않던 현상을 수정 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+38</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>등록된 모델 검색 시간이 초과했습니다. 알림 메시지가 출력되는 오류 현상을 수정 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+37</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>워시타워 LCD 모델 관련 처리를 진행했습니다.</li>
    <li>얼음 정수기 냉장고 관련 문구를 수정 했습니다.</li>
    <li>내장형 FOTA 인증서를 갱신했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+36</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>안드로이드 SDK33의 외부스토리지 오픈관련 문제로 자동업데이트를 더이상 진행할 수 없게되어 업데이트 로직이 변경되었습니다.</li>
    <li>기존 MCS 연동 사용자나 CSMS 연동 사용자는 자동으로 빌드번호를 감지하여 새로운 설치페이지로 안내하도록 처리했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+35</h4>
  <ul class="list-disc ml-4 mb-5">
    <li>애플리케이션 배포 및 업데이트를 위한 새로운 서버가 준비되었습니다.</li>
    <li>배포 전용서버 : https://blackbox.amuz.kr</li>
  </ul>
</template>
