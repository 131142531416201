<template>
  <h4 class="text-2xl py-2">+74 <span class="update_date">(2024.02.23)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>리빙 FOTA RX PGM (SAA44555601)이 추가 되었습니다.</li>
    <li>리빙 FOTA Victor2 PGM (SAA40360116)이 추가 되었습니다.</li>
    <li>리빙 FOTA V+25 PGM (SAA43432404)이 추가 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+73 <span class="update_date">(2024.01.25)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>리빙 FOTA PGM (SAA42446601, SAA43909413, SAA43909402, SAA43432405, SAA43909712, SAA43909702, SAA43432609)이 추가 되었습니다.</li>
    <li>하이케어 FOTA PGM (SAA44378502 - DBEC)이 추가 되었습니다. </li>
    <li>리빙 글로벌 제품군 Wi-Fi 연결 시나리오가 수정(이미지 영문화) 되었습니다. </li>
    <li>비밀번호 찾기 시 페이지 언어(한글, 영문)가 모바일 설정에 맞춰 적용되도록 수정 했습니다. </li>
    <!--    <li>CSMS FOTA 전 모델 확대 기능이 추가 되었습니다.</li>-->
  </ul>
</template>
<script setup>
</script>