<template>
  <h4 class="text-2xl py-2">+72 <span class="update_date">(2024.01.11)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>하이드로타워 SAA44378502 / 9200 PGM 추가되었습니다.</li>
    <li>리빙 SAA43444101, SAA43444102, SAA43444201, SAA43444202 PGM에 대한 문제를 확인하여 시스템 block 처리 되었습니다.</li>
    <!--    <li>CSMS FOTA 전 모델 확대 기능이 추가 되었습니다.</li>-->
  </ul>
  <h4 class="text-2xl py-2">+71 <span class="update_date">(2023.12.16)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>LSS 관리 도메인 주소가 변경되어 앱 전체 API 도메인이 수정되었습니다 (blackbox.lge.amuz.kr => lss.lge.com) </li>
    <li>Global 배기식 건조기 Skin ID 가 추가되었습니다.(LDEXG)</li>
    <!--    <li>CSMS FOTA 전 모델 확대 기능이 추가 되었습니다.</li>-->
  </ul>
</template>
