<template>
  <h4 class="text-2xl py-2">miner</h4>
  <li>이제 모뎀정보(getDeviceInfo)가 10초이내에 수신되지 않으면 데이터 수신 작업이 중단되고 재시도 할 수 있게 조정됩니다.</li>
  <li>케어매니저 모드에서 모델명에 Prefix (CS.)가 자동으로 생성되어 검색되도록 조정됩니다.</li>
  <li>전담핫라인이 개설됩니다. ( 1833 - 5770 / ARS안내시 2번 으로 배정예정이며 월요일정도에 준비가 완료될 예정입니다.) - 담당 : 권오영 과장</li>
  <li>이제 로봇청소기의 진단내역이 작성되고 나면, RAW Data 대신 Zip파일의 경로가 진단내역에 남겨집니다. 이 URL은 곧바로 브라우저를 통해 다운로드받을 수 있습니다.</li>


  <li>FOTA 버전이 업데이트 되었습니다. (리빙 / 0.9.003)</li>
  <li>레거시앱에 NoisCheck 애플리케이션이 추가됩니다.</li>
  <li>FOTA 다이렉트 링크로 변경 (android)</li>


  <li>냉장고 연결안내 UI가 개선됩니다.</li>
  <li>Smart_Styler 등 일부 SSID가 대문자로 발견될때 연결이 되지 않는현상이 수정됩니다.</li>
  <li>SSID 안내페이지가 개선됩니다. (비밀번호가 다르게 표시되거나 하면 바로 알려주세요)</li>


  <li>이제 새 업데이트가 발견되는 경우, 쓰기권한이 없으면 별도의 State에서 새 업데이트가 발견되었으나 권한이없어 실패했음을 알리고 설정창으로 바로 연결 해 줍니다. 이 때 권한이 수락되면 애플리케이션을 재시작 할 수 있게되고, 통신문제 등으로 또 다시 실패하는 경우 애플리케이션 삭제 후 재설치에 관한 안내가 출력됩니다.</li>
  <li>CSMS 및 MCS용 연동 인터페이스가 추가되었습니다. (16버전 안정화 후 각 개발팀에게 개별연락을 드리겠습니다.)</li>


  <h4 class="text-2xl py-2">Request API</h4>
  <li>(GET) https://blackbox.lge.amuz.kr/plugins/lg_e_black_box/mcs_login</li>
  <li>
    <span>
      //SVC 요청
      {
        "group" : "c798a521-84b3-48cf-82bf-f1279377c2c4" //고정
        "email" : "abcd@lge.com",
        "display_name" : "SVC 이름",
        "meta" : {
            "area" : "main | center | south | east 중 하나 선택",
            "store_name" : "서비스 지점명",
          },
      }

      //케어 매니저 요청
      {
        "group" : "51dc08a8-7293-4446-9614-0271cff9c72b" //고정
        "email" : "abcd@lge.com",
        "display_name" : "매니저 이름",
        "meta" : {
            "manager_group" : "", //권역 id
            "model_id" : "RH17VTS.AKOR",
            "serial_number" : "1103020112033"
          },
      }
    </span>
  </li>

  <h4 class="text-2xl py-2">Response - 요청성공</h4>
  <li>
    <span>
      {
        "error" : 0,
        "message" : "success",
        "variables" : {
          "mcsToken" : "effa-esff-segb-xxaa-aaa", // uuid 형식. 단순 보관용.
          "deepLink" : "https://dynamic.link/abcdefg", // 이 링크를 브라우저로 열어주세요.
        }
      }
    </span>
  </li>
  <li>
    누락된 SSID가 보완되었습니다.
  </li>

</template>
