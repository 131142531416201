import Install from "@/components/Install";
import InstallIndex from "@/components/InstallIndex";
import Android from "@/components/InstallAndroid";
import iOS from "@/components/InstallIOS";
import version2410 from "@/release_notes/2.4.10";
import version249 from "@/release_notes/2.4.9";
import version248 from "@/release_notes/2.4.8";
import version247 from "@/release_notes/2.4.7";
import version246 from "@/release_notes/2.4.6";
import version245 from "@/release_notes/2.4.5";
import version244 from "@/release_notes/2.4.4";
import version243 from "@/release_notes/2.4.3";
import version242 from "@/release_notes/2.4.2";
import version241 from "@/release_notes/2.4.1";
import version240 from "@/release_notes/2.4.0";
import version2310 from "@/release_notes/2.3.10";
import version239 from "@/release_notes/2.3.9";
import version238 from "@/release_notes/2.3.8";
import version237 from "@/release_notes/2.3.7";
import version236 from "@/release_notes/2.3.6";
import version235 from "@/release_notes/2.3.5";
import version234 from "@/release_notes/2.3.4";
import version233 from "@/release_notes/2.3.3";
import version232 from "@/release_notes/2.3.2";
import version231 from "@/release_notes/2.3.1";
import version230 from "@/release_notes/2.3.0";
import version220 from "@/release_notes/2.2.0";
import version211 from "@/release_notes/2.1.1";
import version210 from "@/release_notes/2.1.0";
import version2019 from "@/release_notes/2.0.19";
import version2018 from "@/release_notes/2.0.18";
import version2017 from "@/release_notes/2.0.17";
import version2016 from "@/release_notes/2.0.16";
import version2015 from "@/release_notes/2.0.15";
import version2014 from "@/release_notes/2.0.14";
import version2012 from "@/release_notes/2.0.12";
import version2011 from "@/release_notes/2.0.11";
import version2010 from "@/release_notes/2.0.10";
import version2009 from "@/release_notes/2.0.9";
import version2008 from "@/release_notes/2.0.8";
import version2007 from "@/release_notes/2.0.7";
import version2006 from "@/release_notes/2.0.6";

const releaseList = [
    {
      name: '2.4.10+82',
      path: '2.4.10/:withoutLayout?',
      meta : {
        type : "minor",
        ver: "2.4.10",
        build: "82",
        date : "2024.07.24"
      },
      component : version2410,
    },
    {
      name: '2.4.9+81',
      path: '2.4.9/:withoutLayout?',
      meta : {
        type : "minor",
        ver: "2.4.9",
        build: "81",
        date : "2024.07.10"
      },
      component : version249,
    },
    {
      name: '2.4.8+78',
      path: '2.4.8/:withoutLayout?',
      meta : {
        type : "minor",
        ver: "2.4.8",
        build: "78",
        date : "2024.05.09"
      },
      component : version248,
    },
    {
        name: '2.4.7+77',
        path: '2.4.7/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.7",
            build: "77",
            date : "2024.04.11"
        },
        component : version247,
    },
    {
        name: '2.4.6+74',
        path: '2.4.6/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.6",
            build: "74",
            date : "2024.01.25"
        },
        component : version246,
    },
    {
        name: '2.4.5+72',
        path: '2.4.5/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.5",
            build: "72",
            date : "2023.12.16"
        },
        component : version245,
    },
    {
        name: '2.4.4+70',
        path: '2.4.4/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.4",
            build: "70",
            date : "2023.10.18"
        },
        component : version244,
    },
    {
        name: '2.4.3+65',
        path: '2.4.3/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.3",
            build: "65",
            date : "2023.09.13"
        },
        component : version243,
    },
    {
        name: '2.4.2+64',
        path: '2.4.2/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.2",
            build: "64",
            date : "2023.08.30"
        },
        component : version242,
    },
    {
        name: '2.4.1+63',
        path: '2.4.1/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.1",
            build: "63",
            date : "2023.07.19"
        },
        component : version241,
    },
    {
        name: '2.4.0+60',
        path: '2.4.0/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.4.0",
            build: "60",
            date : "2023.06.16"
        },
        component : version240,
    },
    {
        name: '2.3.10+57',
        path: '2.3.10/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.10",
            build: "57",
            date : "2023.05.03"
        },
        component : version2310,
    },
    {
        name: '2.3.9+51',
        path: '2.3.9/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.9",
            build: "51",
            date : "2022.11.02"
        },
        component : version239,
    },
    {
        name: '2.3.8+32',
        path: '2.3.8/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.8",
            build: "32",
            date : "2022.10.20"
        },
        component : version238,
    },
    {
        name: '2.3.7+29~31',
        path: '2.3.7/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.7",
            build: "31",
            date : "2022.10.04"
        },
        component : version237,
    },
    {
        name: '2.3.6+28',
        path: '2.3.6/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.6",
            build: "28",
            date : "2022.10.03"
        },
        component : version236,
    },
    {
        name: '2.3.5+27',
        path: '2.3.5/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.5",
            build: "27",
            date : "2022.09.27"
        },
        component : version235,
    },
    {
        name: '2.3.4+26',
        path: '2.3.4/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.4",
            build: "26",
            date : "2022.09.26"
        },
        component : version234,
    },
    {
        name: '2.3.3+25',
        path: '2.3.3/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.3",
            build: "25",
            date : "2022.09.23"
        },
        component : version233,
    },
    {
        name: '2.3.2+24',
        path: '2.3.2/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.2",
            build: "24",
            date : "2022.09.21"
        },
        component : version232,
    },
    {
        name: '2.3.1+23',
        path: '2.3.1/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.1",
            build: "23",
            date : "2022.09.15"
        },
        component : version231,
    },
    {
        name: '2.3.0+22',
        path: '2.3.0/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.3.0",
            build: "22",
            date : "2022.09.13"
        },
        component : version230,
    },
    {
        name: '2.2.0+21',
        path: '2.2.0/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.2.0",
            build: "21",
            date : "2022.09.06"
        },
        component : version220,
    },
    {
        name: '2.1.1+20',
        path: '2.1.1/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.1.1",
            build: "20",
            date : "2022.08.29"
        },
        component : version211,
    },
    {
        name: '2.1.0+18',
        path: '2.1.0/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.1.0",
            build: "18",
            date : "2022.08.15"
        },
        component : version210,
    },
    {
        name: '2.1.0+18',
        path: '2.1.0/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.1.0",
            build: "18",
            date : "2022.07.29"
        },
        component : version210,
    },
    {
        name: '2.0.19+17',
        path: '2.0.19/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.19",
            build: "17",
            date : "2022.07.24"
        },
        component : version2019,
    },
    {
        name: '2.0.18+16',
        path: '2.0.18/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.18",
            build: "16",
            date : "2022.07.13"
        },
        component : version2018,
    },
    {
        name: '2.0.18+14~16',
        path: '2.0.18/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.18",
            build: "16",
            date : "2022.07.13"
        },
        component : version2018,
    },
    {
        name: '2.0.17+13',
        path: '2.0.17/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.17",
            build: "13",
            date : "2022.06.26"
        },
        component : version2017,
    },
    {
        name: '2.0.16+12',
        path: '2.0.16/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.16",
            build: "12",
            date : "2022.05.15"
        },
        component : version2016,
    },
    {
        name: '2.0.15+11',
        path: '2.0.15/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.15",
            build: "11",
            date : "2022.05.06"
        },
        component : version2015,
    },
    {
        name: '2.0.14+10',
        path: '2.0.14/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.14",
            build: "10",
            date : "2022.04.22"
        },
        component : version2014,
    },
    {
        name: '2.0.12+09',
        path: '2.0.12/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.12",
            build: "09",
            date : "2022.04.09"
        },
        component : version2012,
    },
    {
        name: '2.0.11+08',
        path: '2.0.11/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.11",
            build: "08",
            date : "2022.04.05"
        },
        component : version2011,
    },
    {
        name: '2.0.10+07',
        path: '2.0.10/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.10",
            build: "07",
            date : "2022.04.03"
        },
        component : version2010,
    },
    {
        name: '2.0.9+06',
        path: '2.0.9/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.9",
            build: "06",
            date : "2022.04.02"
        },
        component : version2009,
    },
    {
        name: '2.0.8+05',
        path: '2.0.8/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.8",
            build: "05",
            date : "2022.04.02"
        },
        component : version2008,
    },
    {
        name: '2.0.7+04',
        path: '2.0.7/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.7",
            build: "04",
            date : "2022.03.28"
        },
        component : version2007,
    },
    {
        name: '2.0.6+03',
        path: '2.0.6/:withoutLayout?',
        meta : {
            type : "minor",
            ver: "2.0.6",
            build: "03",
            date : "2022.03.14"
        },
        component : version2006,
    }
];

const routes = [
    {
        name: 'Application Install',
        path: '',
        meta : {
            type : "install",
        },
        component : Install,
        children : [
            {
                path: '',
                component: InstallIndex,
                meta : {
                    type: "install",
                }
            },
            {
                name: 'install_android',
                path: 'android',
                component : Android,
                meta : {
                    type: "install",
                }
            },
            {
                name: 'install_ios',
                path: 'ios',
                component : iOS,
                meta : {
                    type: "install",
                }
            }
        ]
    },
    ...releaseList
];

export default routes;
