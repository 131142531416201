//firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//vue
import { createApp } from "vue";
import router from "./router";
import App from './App.vue'
import './style.css'

const firebaseConfig = {
    apiKey: "AIzaSyARjSMU9vOEYKH6_V2KuRGcW8L-lg_nAeY",
    authDomain: "lge-hna-blackbox.firebaseapp.com",
    projectId: "lge-hna-blackbox",
    storageBucket: "lge-hna-blackbox.appspot.com",
    messagingSenderId: "642489606909",
    appId: "1:642489606909:web:35bdb9090d3caed1fbd494",
    measurementId: "G-6QZ09BG27M"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = createApp(App);

const userAgent = navigator.userAgent.toLowerCase();

let downloadLink = 'https://hnablackbox.page.link/install';
console.log('userAgent', userAgent );
if (!userAgent.includes('android' || 'iphone' || 'ipad')) {
  downloadLink = 'https://blackbox.amuz.kr/release/';
}

app.provide('downloadLink', downloadLink)
app.provide('androidLink', 'https://blackbox.amuz.kr/applications/android/lss.apk')
app.provide('androidTestLink', 'https://blackbox.amuz.kr/applications/android/lss_test.apk')
app.provide('iosLink', 'itms-services://?action=download-manifest&url=https://blackbox.amuz.kr/applications/ios/manifest.plist')
app.provide('iosTestLink', 'itms-services://?action=download-manifest&url=https://blackbox.amuz.kr/applications/ios_test/manifest.plist')
//end
app.provide('androidFotaLink', 'https://blackbox.amuz.kr/applications/android/hna-blackbox-for-fota.apk')
app.provide('iosFotaLink', 'itms-services://?action=download-manifest&url=https://blackbox.amuz.kr/applications/ios_fota/manifest.plist')
app.provide('iosFotaTestLink', 'itms-services://?action=download-manifest&url=https://blackbox.amuz.kr/applications/ios_fota_test/manifest.plist')

app.use(router);

//라우터가 변수를 인식하기전에 마운트되는것을 방지하기위해 마운트시점을 늦춤
router.isReady().then(() => {
    app.mount('#app')
})
// app.mount("#app");
console.log(' -------- Firebase Analytics log --------', analytics);
