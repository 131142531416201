<template>
  <h4 class="text-2xl py-2">+65 <span class="update_date">(2023.09.13)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>리빙 청소기 무선 청소기 A9X Wi-Fi 연결 안내 페이지 추가 되었습니다.</li>
    <li>세탁기 워시타워 LCD, 건조기 워시타워 LCD Wi-Fi 연결 안내 페이지 추가 되었습니다.</li>
    <li>식기세척기 블랙박스 Wi-Fi 연결 안내 페이지 수정 했습니다.</li>
    <li>에어컨 Wi-Fi 연결 안내 페이지 수정 및 LGE_AC2 패턴 추가 했습니다.</li>
    <li>Soft AP 대응 완료 했습니다.</li>
    <li>RSD 기능이 추가 되었습니다.</li>
    <li>지그, 무드업 기능 사용에 이상 현상을 수정 했습니다.</li>
    <li>RSD QR코드로 모델 검색 가능 하도록 기능 추가 했습니다.</li>
    <li>RSD 시리얼 넘버 입력 가능한 페이지 추가 했습니다.</li>
  </ul>
</template>
