<template>
  <h4 class="text-2xl py-2">+70 <span class="update_date">(2023.12.06)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>D3 Tool 포량 보정 FOTA가 추가 되었습니다. [SAA43349801, SAA43349803, SAA43349802, SAA43349805] </li>
    <li>RSD 로직룰 및 결과 페이지 오류 수정되었습니다.</li>
    <li>트러블 슈팅 버튼 클릭 시 로그를 기록 기능이 추가 되었습니다.</li>
    <!--    <li>CSMS FOTA 전 모델 확대 기능이 추가 되었습니다.</li>-->
  </ul>
  <h4 class="text-2xl py-2">+69 <span class="update_date">(2023.11.27)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>모뎀 FOTA 업데이트 기능 이상이 발생하여 핫픽스 적용 했습니다.</li>
    <li>미국, 인도 계정 대상 트러블 슈팅 링크 버튼 추가 했습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+68 <span class="update_date">(2023.11.15)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>비밀번호 찾기 텍스트 문구 변경 했습니다.</li>
    <li>미국 회원은 메인 페이지 하단에 헬프 데스크 버튼이 하단에 출력 되도록 추가 했습니다</li>
    <li>케어매니저 모델명 검색 이슈가 있어 기능 수정 했습니다.</li>
    <li>서명 페이지 접수번호 입력 란에 텍스트를 입력해도 글자가 보이지 않는 현상을 수정 했습니다.</li>
    <li>서명 페이지 접수번호 입력 글자수를 13자리로 변경했습니다.</li>
    <li>모뎀펌웨어 3종 버전이 업데이트 되었습니다. [RTK_RTL8720cm: 195, RTK_RTL8711am: 195, QCOM_QCA4010: 194]</li>
  </ul>
  <h4 class="text-2xl py-2">+67 <span class="update_date">(2023.10.31)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>세탁기 D3WT SAA43349801 제품 FOTA 모델 추가 되었습니다.</li>
    <li>냉장고 M-Victoria SAA44296901 제품 FOTA 모델 추가 되었습니다.</li>
    <li>냉장고 M-VK SAA43871601 제품 FOTA 모델 추가 되었습니다.</li>
    <li>냉장고 SAA44603901 제품 FOTA 모델 추가 되었습니다.</li>
    <li>FOTA 인증서를 인앱이 아닌 서버에서 관리하도록 기능 수정 되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+66 <span class="update_date">(2023.10.18)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>앱 기동 시 Amuz->LG 서버 이전을 위한 안내 공지 페이지가 출력 됩니다.</li>
    <li>블랙박스, RSD 모델검색 서포트 기능이 추가 되었습니다.</li>
    <li>블랙박스 진단 시 약관 동의를 받지 않도록 수정 되었습니다.</li>
    <li>인도 회원 계정은 블랙박스 RSD 진단 시 접수번호를 입력할 수 있도록 폼이 추가 되었습니다.</li>
    <li>내장형 FOTA D3/D3WT (20,21Kg Steam/None Steam) 모델이 추가되었습니다.</li>
    <li>Soft AP WT FOTA 사용시 모뎀 정보 조회 시 먹통이 되는 현상을 수정 했습니다.</li>
  </ul>
</template>
